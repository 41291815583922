import vuexStore from "@/store/rootStore";
import {GenproxState} from "@/modules/genprox/store/types";
import {Contexts, UserContext} from "@/modules/genprox/models/User";

export default class ContextHelper {

    static get activeContext(): UserContext {
        return (vuexStore.state['genprox'] as GenproxState).activeUserData.context;
    }

    static isFund(context: Contexts){
        return context === 'fund';
    }

    static isFundManager(context: Contexts){
        return context === 'fund-manager';
    }

    static isPrivateFundManager(context: Contexts) {
        return context === 'private-fund-manager';
    }

    static get isActiveContextFund(): boolean {
        return this.activeContext.context === 'fund';
    }

    static get isActiveContextFundManager(): boolean {
        return this.activeContext.context === 'fund-manager';
    }

    static get isActiveContextCompany(): boolean {
        return this.activeContext.context === 'company';
    }
}
